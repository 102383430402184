<template>
  <div>
    <maca-modal
      titulo="Nuevo Tipo de Beneficiario"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Nombre" prop="nombre">
            <el-input maxlength="100" v-model="form.nombre"></el-input>
          </el-form-item>

          <el-form-item label="Descripción" prop="descripcion">
            <el-input maxlength="300" v-model="form.descripcion"></el-input>
          </el-form-item>
          <el-form-item label="Habilitado" prop="habilitado">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueHabilitado"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="SI"
              inactive-text="NO"
            >
            </el-switch>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Código" prop="codigo">
            <el-input maxlength="100" v-model="form.codigo"></el-input>
          </el-form-item>
          <el-form-item label="Nombre" prop="nombre">
            <el-input maxlength="100" v-model="form.nombre"></el-input>
          </el-form-item>

          <el-form-item label="Descripción" prop="descripcion">
            <el-input maxlength="300" v-model="form.descripcion"></el-input>
          </el-form-item>
          <el-form-item label="Habilitado" prop="habilitado">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueHabilitado"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="SI"
              inactive-text="NO"
            >
            </el-switch>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>


<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      form: {
        nombre: null,
        codigo: null,
        descripcion: null,
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      persona: null,
      valueHabilitado: Boolean,

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        codigo: [
          {
            required: true,
            message: "Por favor introduzca el codigo.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get(
        "/tipoBeneficiario/obtenerDatos",
        {
          id: this.id,
        }
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.codigo = respuestaApi.datos.codigo;
        this.form.nombre = respuestaApi.datos.nombre;
        this.form.descripcion = respuestaApi.datos.descripcion;
        respuestaApi.datos.habilitado == 1
          ? (this.valueHabilitado = true)
          : (this.valueHabilitado = false);
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {        
        id: this.id,
        codigo: this.form.codigo,
        nombre: this.form.nombre,
        descripcion: this.form.descripcion,
        habilitado: this.valueHabilitado,
      };

      let respuestaApi = await this.$maca.api.post(
        "/tipoBeneficiario/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Tipo Beneficiario actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>