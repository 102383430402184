var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Nuevo Tipo de Beneficiario",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-card",
            {
              staticClass: "mostrarOcultarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "left",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Nombre", prop: "nombre" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100" },
                        model: {
                          value: _vm.form.nombre,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "nombre", $$v)
                          },
                          expression: "form.nombre"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Descripción", prop: "descripcion" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "300" },
                        model: {
                          value: _vm.form.descripcion,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "descripcion", $$v)
                          },
                          expression: "form.descripcion"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Habilitado", prop: "habilitado" } },
                    [
                      _c("el-switch", {
                        staticStyle: { display: "block", "margin-top": "7px" },
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-text": "SI",
                          "inactive-text": "NO"
                        },
                        model: {
                          value: _vm.valueHabilitado,
                          callback: function($$v) {
                            _vm.valueHabilitado = $$v
                          },
                          expression: "valueHabilitado"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("maca-boton-guardar", {
                        attrs: { onSubmit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "ocultarMostrarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "left",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Código", prop: "codigo" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100" },
                        model: {
                          value: _vm.form.codigo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "codigo", $$v)
                          },
                          expression: "form.codigo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Nombre", prop: "nombre" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100" },
                        model: {
                          value: _vm.form.nombre,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "nombre", $$v)
                          },
                          expression: "form.nombre"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Descripción", prop: "descripcion" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "300" },
                        model: {
                          value: _vm.form.descripcion,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "descripcion", $$v)
                          },
                          expression: "form.descripcion"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Habilitado", prop: "habilitado" } },
                    [
                      _c("el-switch", {
                        staticStyle: { display: "block", "margin-top": "7px" },
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-text": "SI",
                          "inactive-text": "NO"
                        },
                        model: {
                          value: _vm.valueHabilitado,
                          callback: function($$v) {
                            _vm.valueHabilitado = $$v
                          },
                          expression: "valueHabilitado"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("maca-boton-guardar", {
                        attrs: { onSubmit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }