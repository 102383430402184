<template>
  <div>
    <maca-modal
      titulo="Nuevo Tipo de Beneficiario"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Nombre" prop="nombre">
            <el-input maxlength="100" v-model="form.nombre"></el-input>
          </el-form-item>

          <el-form-item label="Descripción" prop="descripcion">
            <el-input maxlength="300" v-model="form.descripcion"></el-input>
          </el-form-item>
          <el-form-item label="Habilitado" prop="habilitado">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueHabilitado"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="SI"
              inactive-text="NO"
            >
            </el-switch>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Código" prop="codigo">
            <el-input maxlength="100" v-model="form.codigo"></el-input>
          </el-form-item>
          <el-form-item label="Nombre" prop="nombre">
            <el-input maxlength="100" v-model="form.nombre"></el-input>
          </el-form-item>

          <el-form-item label="Descripción" prop="descripcion">
            <el-input maxlength="300" v-model="form.descripcion"></el-input>
          </el-form-item>
          <el-form-item label="Habilitado" prop="habilitado">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueHabilitado"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="SI"
              inactive-text="NO"
            >
            </el-switch>
          </el-form-item>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      valueHabilitado: true,
      form: {
        codigo: null,
        nombre: null,
        descripcion: null,
      },
      impedirClose: false,
      persona: null,

      urlRol: "/user/obtenerTodosSelectRoles",

      formRules: {
        codigo: [
          {
            required: true,
            message: "Por favor introduzca la razón social.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el CUIL o CUIT.",
            trigger: "change",
          },
        ],
        descripcion: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
      },

      value1: true,
      value2: true,

      esMail: false,
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        codigo: this.form.codigo,
        nombre: this.form.nombre,
        descripcion: this.form.descripcion,
        habilitado: this.valueHabilitado,
      };

      let respuestaApi = await this.$maca.api.post(
        "/tipoBeneficiario/crear",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Empleado creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>